<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 3" fill="none">
        <path fill="currentColor" d="M.5.5h16v2H.5v-2Z" />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
